import React, { useState, useEffect } from 'react';
import axios from 'axios';
import "./footer.css";

const Footer = () => {
const [weather, setWeather] = useState(null);

useEffect(() => {
    const fetchWeather = async () => {
    try {
        const response = await axios.get('http://localhost:3001/api/weather');
        setWeather(response.data);
    } catch (error) {
        console.error("Error fetching weather data", error);
    }
    };

    fetchWeather();
}, []);

return (
    <footer className="footer">
    <div className="footer__container container">
        <div className="footer__content">
        <h1 className="footer__title">Honsa Nghm</h1>
        <ul className="footer__list">
            <li>
            <a href="#home" className="footer__link">Home</a>
            </li>
            <li>
            <a href="#about" className="footer__link">About</a>
            </li>
            <li>
            <a href="#services" className="footer__link">Services</a>
            </li>
            <li>
            <a href="#contact" className="footer__link">Contact Me</a>
            </li>
        </ul>
        <div className="footer__social">
            <a href="https://www.instagram.com" className="footer__social-link">
            <i className="bx bxl-instagram"></i>
            </a>
            <a href="https://www.linkedin.com" className="footer__social-link">
            <i className="bx bxl-linkedin"></i>
            </a>
            <a href="https://github.com" className="footer__social-link">
            <i className="bx bxl-github"></i>
            </a>
        </div>
        </div>
        {weather && (
        <div className="footer__weather">
            <h3 className="footer__weather-title">Current Weather</h3>
            <p>{weather.city}</p>
            <p>{weather.temperature}°C</p>
            <p>{weather.description}</p>
        </div>
        )}
        <div className="footer__docs">
        <h3 className="footer__docs-title">API Documentation</h3>
        <ul>
            <li>
            <a href="http://localhost:3001/api-docs" target="_blank" rel="noopener noreferrer">
                View API Documentation
            </a>
            </li>
        </ul>
        </div>
    </div>
    </footer>
);
};

export default Footer;
