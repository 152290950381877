import React from 'react'

const Info = () => {
return (
    <div className="about__info grind">
        <div className="about__box">
        <i class='bx bx-star about__icon'></i>

            <h3 className="about__title">Experience</h3>
            <span className="about__subtitle">6 months Working</span>
        </div>

        <div className="about__box">
        <i class='bx bx-box about__icon'></i>

            <h3 className="about__title">Completed</h3>
            <span className="about__subtitle">Several Projects </span>
        </div>

        <div className="about__box">
        <i class='bx bxs-timer about__icon'></i>

            <h3 className="about__title">Support</h3>
            <span className="about__subtitle">On Time</span>
        </div>
    </div>
)
}

export default Info;