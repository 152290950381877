import React, {useState} from 'react';
import "./services.css";


const Services = () => {
    const [toggleState, setTogggleState] = useState(0);

    const toggleTab = (index) => {
        setTogggleState(index);
    }


return (
    <section className="services section" id="services">
        <h2 className="section__title">Services</h2>
        <span className="section__subtitle">What I Provide</span>

        <div className="services__container container grid">
            <div className="services_content">
                <div>
                    <i className="uil uil-web-grid services__icon"></i>
                    <h3 className="services__title">Product <br /></h3>
                </div>

                <span className="services__button"onClick={() => toggleTab(1)}>View More
                    <i className="uil uil-arrow-right services__button-icon"></i></span>

                    <div className={toggleState === 1 ? "services__modal active-modal" : "services__modal"}>
                        <div className="services__modal-content">
                            <i onClick={() => toggleTab(0)} className="uil uil-times services__modal-close"></i>

                            <h3 className="services__modal-titles">How I Can Help</h3>
                            <p className="services__modal-descriptions">Delivering solutions to 
                                clients and companies through innovative ideas.</p>

                                <ul className="services__modal-services grid">
                                    <li className="services__modal-service">
                                        <i className="uil uil-check-circle services__modal-icon"></i>
                                        <p className="services__modal-info">Mobile apps: build android app</p>
                                    </li>

                                    <li className="services__modal-service">
                                        <i className="uil uil-check-circle services__modal-icon"></i>
                                        <p className="services__modal-info">Web app development: Building 
                                            and maintaining websites</p>
                                    </li>

                                    <li className="services__modal-service">
                                        <i className="uil uil-check-circle services__modal-icon"></i>
                                        <p className="services__modal-info">Database Management: Designing, 
                                            implementing, and managing databases</p>
                                    </li>


                                </ul>
                        </div>
                    </div>
            </div>
        </div>
    </section>
);
};

export default Services;